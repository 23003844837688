<template>
	<button
		:type="type"
		:class="classes"
		:disabled="disabled"
		@click="sendEvt"
	>
		<img v-if="iconBefore" class="button__icon button__icon_before" :src="iconBefore" alt="icon"/>
		<p v-if="title" class="button__title">{{ title }}</p>
		<p v-if="page" class="button__page">{{ page }}</p>
		<img v-if="iconAfter" class="button__icon button__icon_after" :src="iconAfter" alt="icon"/>
	</button>
</template>

<script>

export default {
	name: 'Button',
	props: {
		title: String,
		iconBefore: String,
		iconAfter: String,
		disabled: {
			type: Boolean,
			default: false
		},
		page: [Number, String],
		styleType: String,
		type: {
			type: String,
			default: 'button'
		},
		active: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		classes() {
			let classes = ['button'];
			if (this.styleType) {
				classes.push('button_type_' + this.styleType);
			}
			if (this.active) {
				classes.push('button_type_' + this.styleType + '_active');
			}
			if (this.disabled) {
				classes.push('button_disabled');
			}
			return classes;
		},
	},
	methods: {
		sendEvt(event) {
			event.preventDefault();
			this.$emit('btnClick')
		}
	}
}
</script>

<style lang="scss">
.button {
	$this: &;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-white;
	font-family: $font;
	font-weight: 500;
	font-size: 14px;
	background-color: $color-danger;
	border: 1px solid transparent;
	border-radius: 5px;
	cursor: pointer;
	transition: $transition-main;
	height: 46px;
	padding: 0 10px;
	width: 100%;
	pointer-events: initial;


	&__icon_after {
		width: 24px;
		height: 24px;
		margin-left: 12px;
	}

	&__icon_before {
		width: 24px;
		height: 24px;
	}

	&:hover {
		background-color: $color-white;
		color: $color-danger;
		border: 1px solid $color-danger;
		transition: $transition-main;
	}

	&_active {
		color: $color-white;
		background-color: $color-danger;
		border: 1px solid transparent;
	}


	&_type_white {
		background-color: $color-white;
		color: $color-danger;
		border: 1px solid $color-danger;

		&:hover {
			background-color: $color-danger;
			color: $color-white;
			border: 1px solid transparent;
		}

		&_active {
			color: $color-white;
			background-color: $color-danger;
			border: 1px solid transparent;
		}
	}

	&_type_circle {
		border-radius: 50%;
		width: 36px;
		height: 36px;
		padding: 0;

		&:hover {
			background-color: $color-danger;
			border: 1px solid transparent;
			box-shadow: 0px 1px 9px $color-danger;
		}
	}

	&_type_pagination {
		padding: 0;
		width: 32px;
		height: 32px;
		background-color: $color-white;
		color: $color-danger;
		border: 1px solid $color-danger;

		&:hover {
			background-color: $color-danger;
			color: $color-white;
			border: 1px solid transparent;
			transition: $transition-main;
		}

		&_active {
			background-color: $color-danger;
			color: $color-white;
			border: 1px solid transparent;
		}
	}

	&_type_close {
		padding: 0;
		width: 24px;
		height: 24px;
		background-color: $color-white;
		border: 1px solid transparent;

		&:hover {
			border: 1px solid transparent;
			background-color: transparent;
			transform: rotate(-180deg);
		}
	}

	&_type_follow_up {
		padding: 0;
		width: 40px;
		height: 40px;
		transform: rotate(-90deg);
		opacity: .35;

		&:hover {
			background-color: $color-danger;
			border: 1px solid transparent;
			opacity: 1;
		}
	}

	&_type_header {
		height: 40px;
	}

	&_type_icon {
		padding: 0;
		width: 24px;
		height: 24px;
		background-color: $color-white;
		border: 1px solid transparent;

		&:hover {
			background-color: transparent;
			border: 1px solid transparent;
		}
	}

	&_type_error {
		height: 36px;
		border-radius: 10px;
		padding: 0 16px;

		.button__icon_before {
			margin-right: 10px;
		}

		&:hover {
			background-color: $color-danger;
			color: $color-white;
		}
	}

	&_type_filter {
		width: 45px;
		height: 48px;
		background: $color-grey-10;
		padding: 10px;
	}

	&_type_download {
		background-color: $color-white;
		color: $color-danger;
		@include fontStyle(14px, 400);
		text-decoration: underline;

		.button__icon_before {
			margin-right: 10px;
		}
	}

	&_type_with_plus {
		padding: 0;
		height: fit-content;
		color: $color-danger;
		background-color: inherit;
		border: none;

		&:disabled {
			.button__title {
				color: $color-grey-60;

				&::before {
					background: url("~@/assets/icons/plus-icon-grey.svg") no-repeat;
				}

				&::after {
					background: $color-grey-60;
				}
			}
		}

		.button__title {
			position: relative;
			display: flex;
			align-items: center;
			margin: 0;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;

			&::before {
				content: '';
				display: inline-block;
				background: url("~@/assets/icons/plus-icon-red.svg") no-repeat;
				width: 16px;
				height: 16px;
				margin-right: 12px;
			}

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 82%;
				height: 1px;
				margin-left: -31%;
				background: $color-danger;
			}
		}

		&:hover {
			color: $color-danger;
			border: none;
		}
	}

	&_disabled {
		pointer-events: none;
		cursor: default;
		color: $color-grey-60;
		background-color: $color-grey-40;
		border: 1px solid transparent;
	}

}
</style>
<template>
	<div class="checkbox">
		<div
			class="checkbox__item"
			:class="classes"
		>
			<label :for="name"
			>
				<input
					class="checkbox__input"
					type="checkbox"
					:name="name"
					:disabled="disabled"
					:id="name"
					v-model="checked"
					@change="$emit('stateCheckbox', checked)"
				/>
				<span class="checkbox__point"></span>
				<span class="checkbox__text">{{ title }}</span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	name: "Checkbox",
	data() {
		return {
			checked: false,
		};
	},
	emits: ['stateCheckbox'],
	props: {
		name: String,
		state: Boolean,
		disabled: Boolean,
		title: String,
		check: Boolean,
		type: {
			type: String,
			default: ''
		},
	},
	mounted() {
		this.checkState()
	},
	computed: {
		classes() {
			let classes = [];
			if (this.type) {
				classes.push(`checkbox__item_${this.type}`)
			}
			return classes;
		}
	},
	methods: {
		checkState() {
			if (this.check) {
				this.checked = true;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.checkbox {
	display: inline-block;

	label {
		display: block;
		cursor: pointer;
	}

	&__item {
		max-width: 428px;
		width: 100%;
		padding-left: 30px;
		position: relative;
	}

	&__input {
		position: absolute;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);
	}

	&__point {
		width: 18px;
		height: 18px;
		border-radius: 2px;
		position: absolute;
		left: 0;
		top: 0;
		border: 1px solid $color-grey-20;
		transition: $transition-main;
	}

	&__point:before {
		display: none;
		content: url("@/assets/icons/mark_white.svg");
		position: absolute;
		width: 14px;
		height: 14px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

	}

	&__text {
		width: 100%;
		font-style: normal;
		@include fontStyle(14px, 400);
		line-height: 18px;

		& a {
			text-decoration: underline;
			color: $color-danger;
		}
	}

	&__item_filter {
		.checkbox__text {
			font-family: $font, sans-serif;
			@include fontStyle(15px, 400);
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 19px;
			color: $color-dark-grey;
		}

		label input:checked ~ .checkbox__text {
			color: $color-danger;
		}
	}
}

label input:checked ~ .checkbox__point {
	background-color: $color-danger;
	border: 1px solid $color-danger;
}

label input:checked ~ .checkbox__point:before {
	display: block;
}
</style>
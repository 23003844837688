<template>
	<div class="download">
		<label
			class="download__label"
			for="input-download"
			ref="label">
			<span v-if="file">{{ file.name }}</span>
		</label>
		<input
			class="download-block__input"
			:name="`${name}[]`"
			type="file"
			multiple
			id="input-download"
			ref="file"
			@change="fileUpload"
		/>
		<Button styleType="icon" class="btn btn_download btn_download_icon"
		        :iconBefore="icon_btn"
		        @click="Upload"
		/>
	</div>
</template>

<script>
import Button from "@/components/ui/buttons/Button";

export default {
	name: 'InputDownloadSubmitForm',
	components: {
		Button,
	},
	props: {
		icon_btn: {
			type: String
		},
		name: String,
		value: [String, Array, File],
	},
	data() {
		return {
			file: "",
		};
	},
	beforeDestroy() {
		this.file = "";
	},
	watch: {
		value: function () {
			if (this.value === null) {
				this.file = "";
				this.$refs.file.value = "";
			}
		}
	},
	methods: {
		fileUpload(event) {
			this.file = event.target.files[0];
			this.$emit('change', this.file);
		},
		Upload() {
			this.$refs.label.click();
		},
	},
};
</script>

<style lang="scss">

.download-block__input[type="file"] {
	position: absolute;
	top: -500px;
	opacity: 0;
	visibility: hidden;

}

.download {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.download__label {
	display: flex;
	align-items: center;
	height: 40px;
	width: 100%;
	padding-left: 12px;
	overflow: hidden;
	border: 1px solid $color-light-grey;
	cursor: pointer;
	margin-right: 16px;
}

.download__label_square {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
	padding: 0;
	cursor: pointer;
}
</style>

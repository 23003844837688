import form from './modules/form.js';
import user from './modules/user.js';
import main from './modules/main.js';
import comment from './modules/comment.js';
import tenant from './modules/tenant.js';
import review from './modules/review.js';
import formReview from './modules/formReview.js';
import mer from './modules/mer.js';
import minec from './modules/minec.js';
import reports from './modules/reports.js';
import resident from './modules/resident'
import gisip from './modules/gisip'

class Api {
    constructor() {
        this.form = form;
        this.user = user;
        this.main = main;
        this.comment = comment;
        this.tenant = tenant;
        this.review = review;
        this.formReview = formReview;
        this.mer = mer;
        this.minec = minec;
        this.reports = reports;
        this.resident = resident;
        this.gisip = gisip;
    }
}

export default new Api()
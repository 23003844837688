<template>
	<div class="error-modal">
		<div class="error-modal__header">
			<div class="error-modal__title">
				<Title
					styleType="section"
					title="Ошибки по отчету"/>
			</div>
			<div class="error-modal__controls">
				<Button
					styleType="close"
					:iconBefore="require('@/assets/icons/close.svg')"
					@btnClick="close"
				/>
			</div>
		</div>
		<div class="error-modal__content">
			<div class="error-modal__content-header">
				<div class="error-modal__content-column">
					Лист
				</div>
				<div class="error-modal__content-column">
					Строка
				</div>
				<div class="error-modal__content-column">
					Столбец
				</div>
			</div>
			<div class="error-modal__content-list">
				<div
					v-for="(error, index) in modalData.errors"
					class="error-modal__content-item"
				>
					<div class="error-modal__content-column">{{ modalData.errors[index][0].sheet }} </div>
					<div class="error-modal__content-column">{{ modalData.errors[index][0].row }} </div>
					<div v-for="item in error.slice(0,1)">
						{{item. columnCode}}
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Title from "@/components/ui/titles/Title.vue";
import Button from "@/components/ui/buttons/Button.vue";

export default {
	name: "ErrorsModal",
	components: {Button, Title},
	props: {
		modalData: [Object]
	},
	methods: {
		close() {
			this.$emit('close')
		},
	}
}
</script>

<style scoped lang="scss">
.error-modal {
	display: flex;
	flex-direction: column;
	max-width: 700px;
	min-width: 400px;
	border-radius: 10px;
	background-color: $color-white;
	transition: $transition-main;
	padding: 40px;

	&__header {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	&__content-header {
		//text-align: center;
		display: flex;
		font-weight: 500;
		padding-bottom: 10px;
	}

	&__content-list {
		height: 390px;
		overflow-y: scroll;
		scrollbar-width: thin;
		scrollbar-color: $color-light-grey;
	}

	&__content-column {
		width: 140px;
	}



	&__content-item {
		display: flex;
		padding: 10px 29px 10px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}
}
</style>
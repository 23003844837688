<template>
	<div class="header">
		<div class="header__container">
			<div class="header__logo">
				<img src="@/assets/icons/logo.svg" alt="logo" class="logo">
			</div>
			<div class="header__title">
				<Title
					title="Отчеты"
					styleType="header"
				/>
			</div>
			<div class="header__dates">
				<Time/>
				<Date/>
			</div>
			<div class="header__btn">
				<Button class="header__btn-item"
				        v-if="user"
				        styleType="header"
				        title="Личный кабинет резидента"
				        @click="goToPersonalArea"
				/>
			</div>
			<div class="header__user">
				<User
					v-if="user"
					:name="user.name"
					:surname="user.lastName"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Button from '@/components/ui/buttons/Button';
import Date from "@/components/ui/dates/Date";
import Time from "@/components/ui/dates/Time";
import Title from "@/components/ui/titles/Title";
import User from "@/components/template/User";

export default {
	name: 'Header',
	components: {
		Button,
		Title,
		Date,
		Time,
		User
	},
	props: {
		userData: Object,
	},
	data() {
		return {}
	},
	computed: {
		dataRoute() {
			return this.$route
		},
		user() {
			if (this.userData) {
				return this.userData.user;
			}
			return null;
		}
	},
	mounted() {
	},
	methods: {
		goToPersonalArea() {
			if (this.user && this.user.lkUrl) {
				window.location.href = this.user.lkUrl
			}
		}
	}
};
</script>

<style lang="scss">
.header {
	width: 100%;
	height: 60px;
	position: sticky;
	top: 0;
	box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
	z-index: 99;
	background-color: $color-white;
	transition: $transition-main;

	&__container {
		margin: 0 auto;
		max-width: $max-content-xl;
		width: 100%;
		display: flex;
		align-items: center;
		background: $color-white;
		height: 60px;
	}


	&__logo {
		padding-right: 12px;
		position: relative;
		align-self: baseline;
		margin-right: 12px;

		&:after {
			content: '';
			position: absolute;
			border-right: 1px solid $color-grey-40;
			height: 40px;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

	}

	&__btn-item_mob {
		display: none;
	}

	&__title {
		margin-left: 0;
		margin-right: auto;

		.title {
			font-size: 14px;
		}

	}

	&__dates {
		display: flex;

		.time {
			margin-right: 34px;
		}
	}

	&__btn {
		margin-right: 0;
		margin-left: auto;
	}

	&__user {
		margin-right: 0;
		margin-left: 34px;
	}
}

@media screen and (max-width: $max-xl) {
	.header {
		&__container {
			padding: 0 10px;
		}
	}
}

@media screen and (max-width: $max-lg) {
	.header {
		&__dates {
			display: none;
		}
	}
}

@media screen and (max-width: $max-md) {
	.header {
		&__user {
			display: none;
		}

		&__title {
			display: none;
		}
	}
}


</style>


<template>
	<div class="select"
	     :class="{'select_disabled': isDisabled }"
	     @blur="hideOptions"
	     tabindex="0"
	>
		<div class="select__title"
		     v-if="title">
			{{ title }}
		</div>
		<div class="select__container">
			<div class="select__result-wrap"
			     :class="{'select__result-wrap_active': showOptions }"
			     @click="toggleShowOptions"
			>
				<p class="select__result"
				   v-if="valueSelect && valueSelect.title"
				> {{ valueSelect.title }}</p>
				<p class="select__result"
				   v-else>
					{{ this.placeholder }}
				</p>
			</div>
			<ul class="select__options"
			    :class="{'select__options_show': showOptions }"
			    v-if="!isDisabled"
			>
				<li class="select__option"
				    :class="{'select__option_active': item.value == active}"
				    v-for="item in dataSelect"
				    :key="item.id"
				    @click="emitItem(item) "
				>
					<span class="select__option-result"> {{ item.title }}</span>
				</li>
			</ul>
		</div>
	</div>

</template>
<script>
export default {
	name: 'Select',
	props: {
		title: String,
		dataSelect: Array,
		placeholder: {
			type: String,
			default: "Не выбрано"
		},
		inputData: [Array, Object],
		active: [String, Number],
		isDisabled: Boolean,
		entityId: [String, Number],
		isRequired: Boolean
	},
	data() {
		return {
			showOptions: false,
			valueSelect: {
				title: null,
				value: 0
			},
		};
	},
	mounted() {
		this.init();
	},
	watch: {
		active: 'init'
	},
	methods: {
		init() {
			if (this.dataSelect && this.active) {
				this.valueSelect = this.dataSelect.find(el => el.value == this.active)
			}
		},
		toggleShowOptions() {
			this.showOptions = !this.showOptions
		},
		hideOptions() {
			this.showOptions = false;
		},
		emitItem(item) {
			this.valueSelect = item;
			this.hideOptions();
			let data = item;
			if (this.entityId) {
				data = {
					id: this.entityId,
					value: item.value,
					required: this.isRequired
				};
			}

			this.$emit('sendEvt', data);
		}
	},
};
</script>

<style lang="scss">

.select {
	display: flex;
	align-items: center;
	position: relative;
	background: transparent;
	font-family: $font;
	width: 100%;

	&_disabled {
		.select__container {
			background-color: $color-light-grey;
		}

		.select__result-wrap {
			border: none;
			cursor: default;

			&:hover {
				border: none;

				&::before {
					background: url("~@/assets/icons/arrow_down.svg") no-repeat center;
				}
			}
		}
	}

	&__title {
		font-size: 16px;
		font-weight: 500;
		white-space: nowrap;
		margin-right: 20px;
		color: $color-dark-grey;
	}

	&__container {
		display: flex;
		position: relative;
		width: 100%;
		border-radius: 5px;
	}

	&__result-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 48px;
		position: relative;
		border: 1px solid $color-light-grey;
		border-radius: 5px;
		transition: $transition-main;
		width: 100%;
		padding-left: 12px;
		padding-right: 34px;
		cursor: pointer;

		&:hover {
			border: 1px solid $color-danger;

			&::before {
				background: url("~@/assets/icons/arrow_down_red.svg") no-repeat center;
			}
		}

		&::before {
			content: '';
			position: absolute;
			display: flex;
			right: 12px;
			top: 50%;
			transform: translateY(-50%);
			background: url("~@/assets/icons/arrow_down.svg") no-repeat center;
			width: 14px;
			height: 14px;
		}
	}

	&__result-wrap_active {
		border: 1px solid $color-danger;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;

		&::before {
			background: url("~@/assets/icons/arrow_down_red.svg") no-repeat center;
		}
	}

	&__result {
		font-family: $font;
		font-weight: 400;
		font-size: 16px;
		color: $color-grey;
	}

	&__options {
		display: none;
		flex-direction: column;
		justify-content: space-between;
		transition: $transition-main;
		opacity: 0;
		position: absolute;
		top: 34px;
		left: 0;
		width: 100%;
		background-color: $color-white;
		box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		padding: 0;
		z-index: 99;
	}

	&__options_show {
		opacity: 1;
		display: flex;
	}

	&__option {
		position: relative;
		padding-left: 12px;
		height: 46px;
		display: flex;
		align-items: center;
		border: 1px solid $color-light-grey;
		transition: $transition-main;

		&:hover {
			color: $color-danger;
			border: 1px solid $color-danger;
			cursor: pointer;
		}
	}

	&__option-result {
		font-family: $font;
		font-weight: 400;
		font-size: 16px;
		color: $color-dark-grey;
	}

	&__option_active {
		.select__option-result {
			color: $color-danger;
		}

		&:after {
			content: '';
			position: absolute;
			background-image: url("~@/assets/icons/mark.svg");
			background-size: cover;
			width: 16px;
			height: 12px;
			top: 50%;
			transform: translateY(-50%);
			right: 12px;
			opacity: 1;
			visibility: visible;
		}
	}
}


</style>

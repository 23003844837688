<template>
	<div class="btn-menu"
	     @blur="hideOptions"
	     tabindex="0"
	>
		<div class="btn-menu__container">
			<div class="btn-menu__result-wrap"
			     :class="{'btn-menu__result-wrap_active': showOptions }"
			     @click="toggleShowOptions"
			>
				<p class="btn-menu__result"
				   v-if="valueSelect && valueSelect.title"
				> {{ valueSelect.title }}</p>
			</div>
			<ul class="btn-menu__options"
			    :class="{'btn-menu__options_show': showOptions }"
			>
				<li class="btn-menu__option"
				    v-for="item in optionList"
				    :key="item.id"
				    @click="emitItem(item) "
				>
					<span class="btn-menu__option-result"> {{ item.title }}</span>
				</li>
			</ul>
		</div>
	</div>

</template>
<script>
export default {
	name: 'MultipleBtnMenu',
	emits: ['sendEvt'],
	props: {
		btnList: [Array, Object],
	},
	data() {
		return {
			showOptions: false,
			valueSelect: {
				code: null,
				title: null
			},
		};
	},
	mounted() {
		this.init();
	},
	computed: {
		optionList() {
			if (this.btnList && this.valueSelect) {
				return this.btnList.filter(el => el.code != this.valueSelect.code)
			}
			return null;
		},
		route() {
			if (this.$route) {
				return this.$route;
			}
			return null;
		}
	},
	methods: {
		init() {
			let code;
			if (this.btnList && !this.route.name) {
				this.valueSelect = this.btnList[0];
			}
			if (this.btnList && this.route && this.route.name) {
				if (this.route.name === 'merDetail') {
					code = 'mer'
				} else if (this.route.name === 'minecDetail') {
					code = 'minec'
				} else if (this.route && this.route.params && this.route.params.formId === 'technopark') {
					code = 'technopark'
				} else if (this.route && this.route.params && this.route.params.formId === 'industrial') {
					code = 'industrial'
				} else if (this.route && this.route.params && this.route.params.formId === 'gisip_technopark') {
					code = 'gisip_technopark'
				} else if (this.route && this.route.params && this.route.params.formId === 'gisip_industrial_park') {
					code = 'gisip_industrial_park'
				} else {
					code = this.route.name
				}
				let findElem = this.btnList.find(elem => elem.code === code)
				if (findElem) {
					this.valueSelect = findElem;
					this.emitItem(findElem)
				} else {
					this.valueSelect = this.btnList[0];
				}
			}
		},
		toggleShowOptions() {
			this.showOptions = !this.showOptions
		},
		hideOptions() {
			this.showOptions = false;
		},
		emitItem(item) {
			this.valueSelect = item;
			this.hideOptions();
			this.$emit('sendEvt', this.valueSelect);
		}
	},
};
</script>

<style lang="scss">

.btn-menu {
	display: flex;
	align-items: center;
	position: relative;
	background: transparent;
	font-family: $font;
	min-width: 122px;
	width: 100%;
	justify-content: space-between;

	&__container {
		display: flex;
		position: relative;
		width: 100%;
		border-radius: 5px;
	}

	&__result-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 48px;
		position: relative;
		border-radius: 5px;
		transition: $transition-main;
		width: 100%;
		padding-left: 12px;
		padding-right: 34px;
		cursor: pointer;
		background-color: #A10036;
		border: 1px solid transparent;

		&::before {
			content: '';
			position: absolute;
			display: flex;
			right: 12px;
			top: 50%;
			transform: translateY(-50%) rotate(-90deg);
			background: url("~@/assets/icons/arrow_down_white.svg") no-repeat center;
			width: 14px;
			height: 14px;
			transition: $transition-main;
		}
	}

	&__result-wrap_active {
		border: 1px solid $color-danger;

		&::before {
			content: '';
			position: absolute;
			display: flex;
			right: 12px;
			top: 50%;
			transform: translateY(-50%);
			background: url("~@/assets/icons/arrow_down_white.svg") no-repeat center;
			width: 14px;
			height: 14px;
		}
	}

	&__result {
		font-family: $font;
		font-size: 17px;
		font-weight: 400;
		color: $color-white;
	}

	&__options {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: $transition-main;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 36px;
		left: 0;
		border-radius: 5px;
		min-width: 100%;
		background-color: $color-white;
		box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.05);
		padding: 0;
		z-index: 99;
	}

	&__options_show {
		opacity: 1;
		visibility: visible;

	}

	&__option {
		position: relative;
		padding-left: 12px;
		padding-right: 32px;
		height: 46px;
		display: flex;
		align-items: center;
		border: 1px solid $color-light-grey;
		transition: $transition-main;

		&:first-child {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		&:last-child {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		&:after {
			content: '';
			position: absolute;
			display: flex;
			right: 12px;
			top: 50%;
			transform: translateY(-50%) rotate(-90deg);
			background: url("~@/assets/icons/arrow_down.svg") no-repeat center;
			width: 14px;
			height: 14px;
		}

		&:hover {
			color: $color-danger;
			border: 1px solid $color-danger;
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				display: flex;
				right: 12px;
				top: 50%;
				transform: translateY(-50%) rotate(-90deg);
				background: url("~@/assets/icons/arrow_down_red.svg") no-repeat center;
				width: 14px;
				height: 14px;
			}
		}
	}

	&__option-result {
		font-family: $font;
		font-weight: 400;
		font-size: 16px;
		color: $color-dark-grey;
	}

	&__option_active {
		.btn-menu__option-result {
			color: $color-danger;
		}


	}
}


</style>

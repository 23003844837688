<template>
	<div class="dashboard-support">
		<div class="dashboard-support__container"
		>
			<div class="dashboard-support__title">ТЕХПОДДЕРЖКА ЛК</div>
			<form
				class="support-form"
				ref="submitForm"
				enctype="multipart/form-data"
			>
				<template v-if="preloader > 0">
					<Preloader/>
				</template>
				<template
					v-if="preloader == 0"
				>
					<InputDashboard
						name="name"
						titleLabel="Ваше имя"
						placeholder="Имя"
						required="true"
						:value="name"
						@update="(value, input) => updateValue(value, input)"
					/>
					<InputDashboard
						name="email"
						titleLabel="Ваша электронная почта"
						placeholder="mail@mail.com"
						required="true"
						:value="email"
						re="^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$"
						@update="(value, input) => updateValue(value, input)"

					/>
					<InputDashboard
						name="phone"
						titleLabel="Ваш номер телефона"
						placeholder="+7 926 888 88 88"
						required="true"
						:value="phone"
						@update="(value, input) => updateValue(value, input)"
						re="^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$"
					/>
					<InputDashboard
						name="subject"
						titleLabel="Тема сообщения"
						placeholder="Тема"
						required="true"
						:value="title"
						@update="(value, input) => updateValue(value, input)"
					/>
					<TextArea
						name="message"
						titleLabel="Текст сообщения"
						required="true"
						:value="text"
						@update="(value, input) => updateValue(value, input)"
					/>
					<InputDownloadSubmitForm
						:icon_btn="require('@/assets/icons/download.svg')"
						name="files"
						@change="fileUpload"
						v-model="files"
					/>
					<Button
						title="Отправить"
						type="submit"
						styleType="header"
						@click="submitForm"
					/>
				</template>
			</form>
		</div>
	</div>
</template>

<script>
import InputDashboard from "@/components/ui/inputs/InputDashboard";
import InputDownloadSubmitForm from "@/components/ui/inputs/InputDownloadSubmitForm";
import TextArea from "@/components/ui/inputs/TextAreaDashboard";
import Button from "@/components/ui/buttons/Button";
import Preloader from '@/components/ui/Preloader';

export default {
	components: {
		TextArea,
		InputDownloadSubmitForm,
		InputDashboard,
		Button,
		Preloader
	},
	name: "DashboardSupport",
	props: {
		modalData: [Array, Object]
	},
	data() {
		return {
			errors: [],
			name: null,
			email: null,
			phone: null,
			text: null,
			title: null,
			files: null,
			preloader: 0,
		};
	},
	watch: {
		statePostSupportForm: 'stateForm'
	},
	computed: {
		statePostSupportForm() {
			return this.$store.getters.GET_STATE_POST_SUPPORT_FORM;
		}
	},
	methods: {
		submitForm() {
			this.preloader++;
			let form = this.$refs.submitForm,
				phoneClean,
				formData;
			if (this.phone) {
				phoneClean = this.phone.replace(/[^A-Z0-9]/ig, "");
			}
			formData = new FormData(form);
			formData.set('phone', phoneClean);
			this.$store.dispatch('postSupportForm', formData);
		},
		fileUpload(data) {
			this.files = data;
		},
		resetForm() {
			this.errors = [];
			this.files = this.name = this.email = this.phone = this.text = this.title = null;
		},
		updateValue(value, name) {
			this[name] = value
		},
		stateForm() {
			if (this.statePostSupportForm) {
				this.$emit("close");
				this.resetForm();
				this.preloader--;
			}
			if (this.statePostSupportForm == false) {
				this.preloader--;
			}
		}
	},
};
</script>
<style lang="scss">
.dashboard-support {
	display: flex;


	&__container {
		display: flex;
		flex-direction: column;
		max-width: 360px;
		min-width: 278px;
		width: 100%;
		min-height: 554px;
		max-height: 590px;
		height: 100%;
		cursor: pointer;
		justify-content: center;
		align-items: center;
		background-color: $color-white;
		transition: $transition-main;
		z-index: 99;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-danger;
		height: 48px;
		width: 100%;
		font-family: $font;
		font-weight: 700;
		text-transform: uppercase;
		color: $color-white;
		margin-bottom: 12px;
	}

}


.support-form {
	min-width: 328px;
	width: 100%;
	padding: 0 20px 20px;
	max-height: 530px;
	min-height: 530px;
	height: 100%;

	.download {
		margin-bottom: 12px;
	}

	.preloader {
		height: 100%;
	}
}
</style>

import network from '../network'

export default class Comment {
    static async getComment(data) {
        let res = await network.get(`comment`, data, {encode: false});
        if (res.isSuccess) {
            return res
        }
    }

    static async postComment(data) {
        let res = await network.post(`comment`, data, {encode: false});
        if (res.isSuccess) {
            return res
        }
    }

    static async updateComment(commentId, data) {
        let res = await network.patch(`comment/${commentId}`, data, {encode: false});
        if (res.isSuccess) {
            return res
        }
    }
}
<template>
	<div class="menu">
		<div
			class="menu__multiply"
			v-if="btnMenu">
			<MultipleBtnMenu
				:btnList="btnMenu"
				@sendEvt="changeMenuList"
			/>
		</div>
		<div class="menu__swiper">
			<swiper
				class="menu__list"
				slidesPerView='auto'
				:loop="false"
				:speed="700"
				:spaceBetween="42"
			>
				<swiper-slide
					class="menu__list-slide"
					v-for="item in menuList"
					:key="item.id"
				>
					<router-link
						class="menu__link "
						:class="{'menu__link_active': activeItem(item)}"
						:to="route(item)"
					>
						<div class="menu__title">
							{{ item.title }}
						</div>
					</router-link>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import MultipleBtnMenu from '@/components/ui/buttons/MultipleBtnMenu'

export default {
	name: 'Menu',
	components: {
		Swiper,
		SwiperSlide,
		MultipleBtnMenu
	},
	data() {
		return {
			menuList: null,
			btnMenu: null,
			multiple: false,
		}
	},
	watch: {
		navList: 'loadMenu'
	},
	computed: {
		navList() {
			return this.$store.getters.NAV_LIST
		},
	},
	methods: {
		loadMenu() {
			if (this.navList) {
				if (this.navList.type && this.navList.type == 'multiple') {
					this.menuList = this.navList.menu[0].menu;
					this.multiple = true;
					this.btnMenu = this.navList.menu.map(elMenu => elMenu = {
						code: elMenu.code,
						title: elMenu.title
					})
				} else {
					this.menuList = this.navList;
				}
			}
		},
		route(item) {
			if (item) {
				let route = {
					name: item.path,
					params: {}
				}
				if (item.formId) {
					route.params.formId = item.formId;
				}
				if (item.path === 'mer' || item.path === 'minec') {
					route.params.page = 1
				}
				return route;
			}
		},
		activeItem(item) {
			if (item && this.$route) {
				if (item.formId && this.$route.params && this.$route.params.formId) {
					return item.formId == this.$route.params.formId;
				} else {
					return item.path == this.$route.name
				}
			}
		},
		onRoute(code, menuItem) {
			let name = menuItem.path
			let params = {}

			if (menuItem.formId) {
				params.formId = menuItem.formId
			}

			if (code === 'mer' || code === 'minec') {
				params.page = 1
			}
			this.$router.replace({ name, params })
		},
		changeMenuList(item) {
			if (item) {
				let findMenu = this.navList.menu.find(el => el.code == item.code);
				if (findMenu) {
					let itemMenu = findMenu.menu[0]
					if (this.$route.params.formId) {
						let foundedRoute = findMenu.menu.find(i => i.formId == this.$route.params.formId)
						if (foundedRoute) {
							itemMenu = foundedRoute
						}
					}
					this.menuList = findMenu.menu;
					this.onRoute(item.code, itemMenu)
				}
			}
		}
	}
}
;
</script>

<style lang="scss">
.menu {
	display: flex;
	align-items: center;
	margin: 0 auto 40px;
	max-width: $max-content-xl;
	width: 100%;
	padding-top: 30px;
	justify-content: space-between;

	&__swiper {
		display: flex;
		max-width: calc(100% - 142px);
		width: 100%;
	}

	.swiper {
		// @media screen and (max-width: $max-xl) {
		width: calc(100% - 24px);
		// }
	}

	.swiper-slide {
		width: auto;
	}

	&__link {
		@include fontStyle(17px, 400);
		line-height: 22px;
		color: $color-dark-grey;
		text-decoration: none;
		position: relative;

		&:hover {
			color: $color-danger;
		}
	}

	&__link_active {
		color: $color-danger;
	}
}

</style>

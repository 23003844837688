import network from '../network';

export default class Mer {
    static async getMinec(formId, page, data) {
        let res = await network.get(`minec/${formId}/${page}`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getMinecDetail(route, data) {
        let res = await network.get(`minec/${route.formId}/${route.year}/${route.quarter}`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getMinecModal( data) {
        let res = await network.get(`/minec/filters`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async updMinec(payload) {
        let res = await network.patch(`minec/${payload.formId}/${payload.year}/${payload.quarter}`, payload.data, {encode: false});
        if (res.isSuccess) {
            return res
        }
    }

    static async acceptMinec(payload) {
        let res = await network.post(`minec/${payload.formId}/${payload.year}/${payload.quarter}/accept`, payload.data, {encode: false});
        if (res.isSuccess) {
            return res
        }
    }

    static async postMinecModalData(data) {
        return await network.post(`minec/generate`, data, {});
    }

    static async postBusyEditTable(payload) {
        return await network.post(`/minec/${payload.formId}/${payload.year}/${payload.quarter}`, {});
    }

    static async addReportMinec(payload) {
        let res = await network.post(`minec/${payload.formId}`, {encode: false});
        if (res.isSuccess) {
            return res
        }
    }
}
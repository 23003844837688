<template>
	<div class="message-item"
	     :class="{'message-item_incoming': isIncoming}"
	>
		<div
			v-html="text"
			class="message-item__container"
		>
		</div>
	</div>
	<ChatMessageTime
		:isIncoming="isIncoming"
		:messageTime="messageTime"
	/>
</template>

<script>
import ChatMessageTime from "@/components/chat/ChatMessageTime";
import Checkbox from "@/components/ui/checkbox/Checkbox";

export default {
	name: "ChatMessage",
	components: {
		ChatMessageTime,
		Checkbox
	},
	props: {
		messageData: [Array, Object],
		role: String,
	},
	computed: {
		isIncoming() {
			if (this.messageData && this.messageData.isIncoming) {
				return this.messageData.isIncoming
			}
			return null
		},
		text() {
			if (this.messageData && this.messageData.text) {
				return this.messageData.text
			}
			return null
		},
		messageTime() {
			if (this.messageData && this.messageData.time) {
				return this.messageData.time
			}
			return null
		},
		isNote() {
			if (this.messageData && this.messageData.isNote) {
				return this.messageData.isNote
			}
			return null
		}
	},
}
</script>


<style lang="scss">
.message-item {
	display: flex;
	align-items: center;
	@include fontStyle(15px, 500);


	&__container {
		display: flex;
		line-height: 20px;
		max-width: 50%;
		width: 100%;
		padding: 10px 20px;
		color: $color-white;
		background: $color-danger;
		border-radius: 10px;
		position: relative;
		word-break: break-word;
		margin-left: auto;
		margin-right: 0;

		&::after {
			content: '';
			bottom: 0;
			right: -5px;
			background: url("~@/assets/icons/message-decor-red.svg") no-repeat;
			width: 14px;
			height: 11px;
			position: absolute;
		}
	}


	&_incoming {

		.message-item__container {
			color: $color-dark-grey;
			background: $color-light-grey;
			margin-left: 0;
			margin-right: 22px;

			&::after {
				left: -5px;
				background: url("~@/assets/icons/message-decor-grey.svg") no-repeat;
			}
		}
	}
}
</style>
<template>
	<div class="footer">
		<div class="footer__img-wrapper">
			<img src="@/assets/icons/support.svg" alt="фото" class="footer__img">
		</div>
		<div class="footer__title">Техподдержка личного кабинета</div>
	</div>
</template>

<script>

export default {
	components: {},
	name: 'Footer',
};
</script>
<style lang="scss">
.footer {
	width: fit-content;
	display: flex;
	align-self: center;
	justify-content: center;
	align-items: center;
	padding-bottom: 20px;
	margin-bottom: 0;
	margin-top: auto;
	z-index: 1;

	&__img-wrapper {
		background-color: $color-danger;
		width: 30px;
		height: 30px;
		border-radius: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 16px;
	}

	&__img {
		width: 23px;
		height: 23px;
	}

	&__title {
		font-family: $font;
		font-size: 17px;
		font-weight: 400;
		color: $color-danger;
		text-transform: capitalize;
		cursor: pointer;
	}
}
</style>

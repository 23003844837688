<template>
	<div class="message-block">
		<div class="message-block__date"
		     v-if="blockData.date">
			<ChatMessageDate
				:title="blockData.date"/>
		</div>
		<div class="message-block__item"
		     v-if="blockData.messages"
		     v-for="message in blockData.messages"
		>
			<ChatMessage
				:role="role"
				:messageData="message"
			/>
		</div>
	</div>
</template>

<script>
import ChatMessageDate from "@/components/chat/ChatMessageDate"
import ChatMessage from "@/components/chat/ChatMessage"

export default {
	components: {
		ChatMessageDate,
		ChatMessage,
	},
	name: "ChatMessageBlock",
	props: {
		blockData: [Array, Object],
		role: String,
	}
}
</script>

<style lang="scss">
.message-block {
	display: flex;
	flex-direction: column;
	padding: 30px 36px 0 36px;

	&__item {
		margin-bottom: 32px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__date {
		display: flex;
		width: 100%;
		justify-content: center;
		margin-bottom: 16px;
		margin-top: 42px;

		&:first-child {
			margin-top: 0;
		}
	}
}
</style>
<template>
	<div class="panel">
		<div class="panel__title">{{ title }}</div>
	</div>
</template>

<script>
export default {
	name: "ChatMessageDate",
	props: {
		title: String,
	}
}
</script>

<style lang="scss" scoped>
.panel {
	background: $color-grey-5;
	border-radius: 12px;
	padding: 6px 12px;
	width: fit-content;
	width: -moz-fit-content;

	&__title {
		display: inline-block;
		@include fontStyle(15px, 500);
		font-style: normal;
		line-height: 19px;
	}
}
</style>
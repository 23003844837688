<template>
	<div class="date">
		<img class="date__img" src="@/assets/icons/calendar.svg" alt="icon">
		<p class="date__item"> {{ localDate }}</p>
	</div>
</template>
<script>
export default {
	name: 'Date',
	props: {},
	data() {
		return {
			date: '',
		};
	},
	computed: {
		localDate() {
			return (new Date()).toLocaleDateString('ru-Ru')
		}
	},
	methods: {},
};
</script>

<style lang="scss">
.date {
	display: flex;
	align-items: center;
	justify-content: center;

	&__img {
		display: inline-flex;
		max-width: 16px;
		object-fit: cover;
		margin-right: 6px;
	}

	&__item {
		font-family: $font;
		font-size: 15px;
		font-weight: 500;
		color: $color-grey-20;
		margin: 0;
	}
}
</style>

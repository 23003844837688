import network from '../network';

export default class Tenant {
    static async getFormDetail(route, data) {
        let res = await network.get(`form/tenant/${route.year}`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }
    
    static async postForm(route, data) {
        let res = await network.patch(`form/tenant/${route.year}`, data, {});
        if (res) {
            return res
        }
    }
    
    static async checkBlockForm(isTenant, route, data) {
        let res;
        
        if (isTenant) {
            res = await network.post(`/form/tenant/${route.year}/check/`, data, {});
        } else {
            res = await network.post(`/form/${route.formId}/${route.year}/${route.quarter}/check/`, data, {});
        }
        if (res) {
            return res
        }
    }
}
<template>
	<div class="user">
		<div class="user__img-wrapper">
			<img class="user__img" :src="avatar" alt="аватар" v-if="avatar">
			<img class="user__img" src="@/assets/icons/user_plug.svg" alt="аватар" v-else>
		</div>
		<div class="user__title-wrapper">
			<p class="user__title user__title_surname">{{ surname }}</p>
			<p class="user__title user__title_name">{{ name }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'User',
	props: {
		avatar: {
			type: String,
		},
		surname: {
			type: String,
		},
		name: {
			type: String,
		}
	},
};
</script>

<style lang="scss">
.user {
	display: flex;

	&__img-wrapper {
		max-width: 38px;
		max-height: 38px;
		border-radius: 50%;
		margin-right: 12px;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__title {
		margin: 0;
		font-family: $font;
		font-size: 14px;
		font-weight: 400;
		color: $color-grey-50;
	}
}
</style>

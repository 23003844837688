<template>
	<div class="modal"
	     :class="{'modal_active':modalData && modalData.code}"
	>
		<div class="overlay"
		     v-if="modalData && modalData.code"
		     @click="closeModal"
		>
		</div>
		<div class="modal__container">
			<component
				v-if="component"
				:is="component"
				:modalData="modalData"
				@close="closeModal"
			/>
		</div>
	</div>
</template>

<script>
import DashboardSupport from "@/components/modal/DashboardSupport";
import ChatWindow from "@/components/modal/ChatWindow";
import ReviewModal from "@/components/modal/ReviewModal";
import FilterModal from "@/components/modal/FilterModal.vue";
import TenantModal from "@/components/modal/TenantModal";
import MerModal from "../modal/MerModal";
import MinecModal from "../modal/MinecModal";
import ErrorsModal from "../modal/ErrorsModal";
import GisipModal from "@/components/modal/GisipModal.vue";

export default {
	name: 'Modal',
	components: {},
	data() {
		return {}
	},
	computed: {
		modalData() {
			return this.$store.getters.GET_MODAL_DATA
		},
		component() {
			if (this.modalData) {
				if (this.modalData.code === 'DashboardSupport') {
					return DashboardSupport
				}
				if (this.modalData.code === 'Chat') {
					return ChatWindow
				}
				if (this.modalData.code === 'mer') {
					return MerModal
				}
				if (this.modalData.code === 'minec') {
					return MinecModal
				}
				if (this.modalData.code === 'review' && (this.modalData?.formId === 'tenant' || this.modalData?.formId === 'technopark' || this.modalData?.formId === 'industrial')) {
					return TenantModal
				}
				if (this.modalData.code === 'reviewDetail' || this.modalData.code === 'review') {
					return ReviewModal
				}
				if (this.modalData.code === 'gisip') {
					return GisipModal
				}
				if (this.modalData.code === 'filter') {
					return FilterModal
				}
				if (this.modalData.code === 'errors') {
					return ErrorsModal
				}
			}
			return null
		},

	},
	methods: {
		closeModal() {
			this.$store.commit('SET_MODAL_DATA', null)
		}
	},
};
</script>

<style lang="scss">
.modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	z-index: -999;

	&__container {
		transform: scale(.1);
	}

	&_active {
		visibility: visible;
		opacity: 1;
		z-index: 9;

		.modal__container {
			transform: scale(1);
			transition: $transition-main;
		}

	}
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #374850;
	opacity: .6;
}

</style>


<template>
	<div class="review-modal"
	     :class="{'review-modal_fullWidth':fullWidth}"
	>
		<div class="review-modal__header">
			<div class="review-modal__title"
			>
				<Title
					styleType="section"
					title="Консолидированный отчет"
				/>
			</div>
			<div class="review-modal__controls">
				<Button
					styleType="icon"
					:iconBefore="require('@/assets/icons/window.svg')"
					@btnClick="toggleFullWidth"
				/>
				<Button
					styleType="close"
					:iconBefore="require('@/assets/icons/close.svg')"
					@btnClick="close"
				/>
			</div>
		</div>
		<div class="review-modal__content">
			<div class="review-modal__block"
			     v-if="reviewModalData"
			>
				<div class="review-modal__block-item"
				     v-for="(componentData, index) in reviewModalData"
				     :key="index"
				>
					<div class="review-modal__block-title"
					     v-if="componentData.title"
					>
						{{ componentData.title }}
					</div>
					<component
						v-if="component"
						:is="component(componentData)"
						:entityId="componentData.code"
						:dataSelect="componentData.options"
						@sendEvt="setPostData"
					/>
				</div>
			</div>
		</div>
		<div class="review-modal__footer">
			<Button
				title="Скачать отчет"
				:disabled="disabled"
				@btnClick="downloadReport"
			/>
		</div>
		<Preloader v-if="preloader.review > 0"/>
	</div>
</template>

<script>
import Button from "@/components/ui/buttons/Button"
import Title from "@/components/ui/titles/Title"
import Preloader from '@/components/ui/Preloader';
import MultiSelect from '@/components/ui/select/MultiSelect';
import Select from '@/components/ui/select/Select';

export default {
	components: {
		Button,
		Title,
		Preloader,
		MultiSelect,
		Select
	},
	name: "ReviewModal",
	props: {
		modalData: [Array, Object]
	},
	data() {
		return {
			fullWidth: false,
			postData: [],
			disabled: true
		};
	},
	computed: {
		userData() {
			return this.$store.getters.GET_USER;
		},
		role() {
			if (this.userData?.user && this.userData.user.role) {
				return this.userData.user.role
			}
			return null
		},
		preloader() {
			return this.$store.getters.PRELOADER;
		},
		reviewModalData() {
			if (this.modalData?.code === 'review') {
				return this.$store.getters.GET_REVIEW_MODAL_DATA;
			} else {
				return this.$store.getters.GET_REVIEW_DETAIL_MODAL_DATA;
			}
		},

	},
	mounted() {
		this.$store.dispatch('getTenantModal', this.modalData)
	},
	methods: {
		setPostData(item) {
			this.postData[item.id] = item.value;
			this.disabled = false;
		},
		component(component) {
			if (component.isMultiple) {
				return MultiSelect
			}
			if (!component.isMultiple) {
				return Select
			}
		},
		toggleFullWidth() {
			this.fullWidth = !this.fullWidth
		},
		downloadReport() {
			this.$store.dispatch('getLinkToReports', {...this.postData, ...this.modalData})
		},
		close() {
			this.$emit('close')
		},
	},
};
</script>

<style lang="scss">
.review-modal {
	display: flex;
	flex-direction: column;
	max-width: 700px;
	min-height: 340px;
	border-radius: 10px;
	background-color: $color-white;
	transition: $transition-main;
	padding: 40px;

	&_fullWidth {
		width: calc(100vw - 160px);
		height: calc(100vh - 160px);
	}

	&__header {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 58px;
	}

	&__controls {
		display: flex;
		margin-left: 22px;

		.button {
			margin-right: 20px;

			&__icon_before {
				width: 16px;
				height: 16px;
			}

			&:last-child {
				margin-right: 0;
			}
		}

	}

	&__block-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 600px;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		.multi-select {
			max-width: 500px;
			height: 48px;

			&__options {
				top: 48px;
			}
		}

		.select {
			max-width: 500px;
		}
	}

	&__block-title {
		margin-right: 10px;
		font-family: $font;
		@include fontStyle(16px, 500);
	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		position: relative;
		scrollbar-width: thin;
		scrollbar-color: $color-light-grey;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: $color-light-grey;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: $color-grey-20;
			border-radius: 10px;
		}

		.message-block {

			&:last-child {
				padding-bottom: 30px;
			}
		}

		.preloader {
			position: absolute;
		}
	}

	&__footer {
		display: flex;
		width: 100%;
		align-items: center;
		height: 80px;
		margin-top: 20px;

		.button {
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}
		}
	}


}
</style>

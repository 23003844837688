import network from '../network'

export default class Main {
		static async getMain(role) {
				let res = await network.get(`${role}`);
				if (res) {
					return res
				}
		}
		static async getMainResident(role) {
			let res = await network.get(`${role}/1`);
			if (res) {
				return res
			}
		}

		static async getGeneralResident() {
			let res = await network.get('general');
			if (res) {
				return res
			}
		}
}
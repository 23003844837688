<template>
	<div class="textarea">
		<label
			:for="name"
			class="textarea__label"
			v-if="titleLabel">
			{{ titleLabel }}
		</label>
		<textarea
			:placeholder="placeholder"
			:name="name"
			v-model="valueInput"
			class="textarea__input"
			:class="{ textarea__input_disable: !valid }"
			:required="required"
			cols="30"
			rows="10"
			ref="textArea"
			@input="emitValue(valueInput)"
		/>
	</div>
</template>
<script>
export default {
	name: "TextAreaDashboard",
	props: {
		placeholder: {
			type: String,
		},
		name: {
			type: String,
		},
		titleLabel: {
			type: String,
		},
		re: {
			type: String,
			default: ".{1,50}",
		},
		required: {
			type: String,
		},
		value: {
			type: String,
		}
	},
	data() {
		return {
			valueInput: "",
			valid: true,
		};
	},
	computed: {},
	watch: {
		value: function () {
			if (this.value === null) {
				this.valueInput = "";
				this.valid = false;
			}
		},
	},
	methods: {
		emitValue() {
			let textArea = this.$refs.textArea;
			this.$emit("input", textArea.value);
			this.validInput(textArea);
		},
		validInput(textArea) {
			let reg = new RegExp(this.re);
			if (reg.test(textArea.value)) {
				this.valid = true;
			} else {
				this.valid = false;
			}
			return this.valid;
		},
	},
};
</script>

<style lang="scss" scoped>
.textarea {
	width: 100%;

	&__input {
		max-width: 100%;
		width: 100%;
		height: 80px;
		outline: none;
		padding-left: 12px;
		padding-top: 12px;
		font-family: $font;
		font-size: 15px;
		font-weight: 400;
		resize: none;
		color: $color-grey;
		margin-bottom: 14px;
		border: 1px solid $color-light-grey;

		&::placeholder {
			color: $color-grey;
			font-family: $font;
			font-size: 14px;
			font-weight: 300;
			opacity: 0.5;
		}

		&:focus {
			outline: none;
			border: 1px solid $color-light-grey;
		}

		&_disable {
			border: 1px solid $color-danger;

			&:focus {
				border: 1px solid $color-danger;
			}
		}
	}

	&__label {
		display: block;
		font-family: $font;
		color: $color-grey;
		font-weight: 400;
		font-size: 15px;
		margin-bottom: 4px;
	}
}
</style>

<template>
    <div class="title"
         :class="classes"
         v-if="title"
    >
        {{ title }}
    </div>
</template>

<script>
export default {
    name: "Title",
    props: {
        title: String,
        styleType: String,
    },
    data() {
        return {};
    },
    computed: {
        classes() {
            let classes = ['title'];
            if (this.styleType) {
                classes.push('title_' + this.styleType)
            }
            return classes;
        },
    },
};
</script>

<style lang="scss">
.title {
    background: transparent;
    font-size: 14px;
    font-weight: 700;
    &_header {
        color: $color-grey;
        width: fit-content;
        text-transform: uppercase;
        padding: 0;
    }

    &_section {
        font-size: 24px;
        padding-bottom: 20px;
        color: $color-dark-grey;
        width: fit-content;
        position: relative;
        text-transform: uppercase;
        line-height: 1;

        &:after {
            position: absolute;
            content: " ";
            left: 0;
            bottom: 0;
            width: 85px;
            border: 1px solid $color-danger;
            background-color: $color-danger;
        }
    }

    &_card {
        font-size: 18px;
        color: $color-dark-grey;
    }

    &_card-resident {
        font-size: 18px;
        color: $color-white;
    }

    &_input {
        font-weight: 500;
        color: $color-grey;
    }
}
</style>
<template>
	<div class="textarea"
	     :class="{'input-field_disabled':inputDataIsDisabled}">
    <textarea
	    class="textarea__input"
	    :placeholder="placeholder"
	    rows="3"
	    :name="name"
	    ref="textArea"
	    :value="inputDataValue"
	    :disabled="inputDataIsDisabled"
	    @change="emitValue"
    />
	</div>
</template>

<script>
export default {
	name: "Textarea",
	props: {
		name: String,
		inputData: Object,
		entityId: [String, Number],
	},
	computed: {
		inputDataValue() {
			if (this.inputData && this.inputData.comment) {
				return this.inputData.comment;
			} else if (this.inputData && this.inputData.value) {
				return this.inputData.value;
			}

			return null;
		},
		inputDataIsDisabled() {
			if (this.inputData && this.inputData.isDisabled) {
				return this.inputData.isDisabled;
			}
			return null;
		},
		placeholder() {
			if (!this.inputDataIsDisabled) {
				return 'Введите текст';
			}
			return null;
		}
	},
	methods: {
		emitValue() {
			this.$emit("sendEvt", this.$refs.textArea.value);
		}
	}
}
</script>

<style lang="scss">
.textarea {
	width: 100%;

	&__input {
		max-width: 100%;
		width: 100%;
		height: 80px;
		outline: none;
		border: none;
		resize: none;
		padding: 20px 30px;
		font-family: $font;
		font-size: 16px;
		font-weight: 400;
		color: $color-dark-grey;
		font-style: normal;
		line-height: 21px;
		cursor: auto;
		scrollbar-width: thin;
		scrollbar-color: $color-light-grey;

		&::placeholder {
			color: $color-grey-20;
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: $color-light-grey;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: $color-grey-20;
			border-radius: 10px;
		}
	}
}
</style>

<template>
	<div class="input" :class="objectStyle">
		<label
			class="input__label"
			:for="name"
			v-if="titleLabel">
			{{ titleLabel }}
			<span class="input__label_required"
			      v-if="required">*</span>
		</label>
		<input
			class="input__field"
			:class="{ input__field_disable: !valid }"
			:type="type"
			:placeholder="placeholder"
			:name="name"
			:value="value"
			:required="required"
			ref="input"
			@input="update(name)"
		/>
	</div>
</template>
<script>
export default {
	name: "InputDashboard",
	props: {
		placeholder: String,
		name: String,
		type: {
			type: String,
			default: "text",
		},
		titleLabel: String,
		value: String,
		styleType: String,
		required: String,
		re: {
			type: String,
			default: ".{1,50}",
		},
	},
	data() {
		return {
			valid: true,
		};
	},
	computed: {
		objectStyle() {
			return {
				input_submit: this.styleType === "submit",
			};
		},
	},
	methods: {
		validInput(input) {
			let reg = new RegExp(this.re);
			if (reg.test(input.value)) {
				this.valid = true;
			} else {
				this.valid = false;
			}
			return this.valid;
		},
		update(name) {
			let input = this.$refs.input;
			if (this.validInput(input)) {
				this.$emit('update', input.value, name)
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.input {
	width: 100%;

	&__field {
		max-width: 100%;
		width: 100%;
		height: 40px;
		outline: none;
		padding-left: 12px;
		border: 1px solid $color-light-grey;
		font-family: $font;
		font-size: 15px;
		font-weight: 400;
		color: $color-grey;
		margin-bottom: 8px;

		&::placeholder {
			color: $color-grey;
			font-family: $font;
			font-size: 14px;
			font-weight: 300;
			opacity: 0.5;
		}

		&:focus {
			outline: none;
			border: 1px solid $color-light-grey;
		}

		&_disable {
			border: 1px solid $color-danger;

			&:focus {
				border: 1px solid $color-danger;
			}
		}
	}

	&__label {
		display: block;
		font-family: $font;
		color: $color-grey;
		font-weight: 400;
		font-size: 15px;
		margin-bottom: 4px;
	}

	&__label_required {
		color: $color-danger;
	}

	&_submit {
		.input__field {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color-white;
			font-family: $font;
			font-weight: 500;
			font-size: 16px;
			text-transform: uppercase;
			background-color: $color-danger;
			border: 1px solid transparent;
			border-radius: 5px;
			cursor: pointer;
			transition: $transition-main;
			height: 40px;
			padding: 0 30px;
			width: auto;

			&:hover {
				background-color: $color-red-light;
			}
		}
	}
}
</style>

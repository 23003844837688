import network from '../network';
import utils from '@/assets/js/utils';

export default class FormReview {
    
    static async setVerify(route, data) {
        let res;
        
        if (!route.quarter) {
            res = await network.post(`form/${route.formId}/${route.year}/verify`, data, {});
        } else {
            res = await network.post(`form/${route.formId}/${route.year}/${route.quarter}/verify`, data, {});
        }
        if (res.isSuccess) {
            return res
        }
    }
    
    static async setRework(route, data) {
        let res;
        if (!route.quarter) {
            res = await network.post(`form/${route.formId}/${route.year}/rework`, data, {});
        } else {
            res = await network.post(`form/${route.formId}/${route.year}/${route.quarter}/rework`, data, {});
        }
        if (res.isSuccess) {
            return res
        }
    }
    
    static async setUnderReviewStatus(route, data) {
        let res;
        if (!route.quarter) {
            res = await network.post(`form/${route.formId}/${route.year}/underReview`, data, {});
        } else {
            res = await network.post(`form/${route.formId}/${route.year}/${route.quarter}/underReview`, data, {});
        }
        if (res.isSuccess) {
            return res
        }
    }
    static async getDownloadLink(route) {
        let res;
        if (!route.quarter) {
            res = await network.get(`download/tenant//${route.year}`, null, {});
        }
        if (res.isSuccess) {
            return res
        }
    }
}


<template>
	<div :class="classes">
		<template v-if="type === 'row'">
			<div class="preloader__container">
				<div/>
				<div/>
				<div/>
			</div>
		</template>
		<div class="loader" v-else></div>
	</div>
</template>
<script>

export default {
	name: 'Preloader',
	props: {
		type: {
			type: String,
		},
	},
	computed: {
		classes() {
			let classes = ['preloader'];
			if (this.type) {
				classes.push(`preloader_${this.type}`)
			}
			return classes;
		},
	}
};
</script>

<style lang="scss" scoped>
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	display: flex;
	transition: opacity .3s ease;
	backdrop-filter: blur(10px);
	z-index: 999;

	&_row {
		position: absolute;
		backdrop-filter: blur(10px);
		z-index: 8;

		.preloader__container {
			width: 100%;
			height: 100%;

			animation: gradient 2s ease infinite;
			background: darken($color-grey-5, 4);
			background: linear-gradient(90deg, darken($color-grey-5, 4) 33%, $color-white 50%, darken($color-grey-5, 4) 66%);
			background-size: 300% 100%;
			box-shadow: none;

			@keyframes gradient {
				0% {
					background-position: right;
				}
			}

		}
	}

	&_square {
		.preloader__container {
			position: fixed;
			width: 28px;

			div {
				position: absolute;
				width: 6px;
				height: 15px;
				top: 50%;
				background: $color-danger;
				transform: translateY(-50%);
				animation: square 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
			}

			div:nth-child(1) {
				left: 0;
				animation-delay: -0.24s;
			}

			div:nth-child(2) {
				left: 50%;
				margin-left: -3px;
				animation-delay: -0.12s;
			}

			div:nth-child(3) {
				right: 0;
				animation-delay: 0s;
			}


			@keyframes square {
				0% {
					height: 15px;
				}
				10% {
					height: 30px;
				}
				50%, 100% {
					height: 15px;
				}
			}
		}
	}
}

.loader {
	color: $color-danger;
	font-size: 10px;
	width: 1em;
	height: 1em;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	position: relative;
	text-indent: -9999em;
	-webkit-animation: load4 1.3s infinite linear;
	animation: load4 1.3s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translate(-50%, -50%);
}

@keyframes load4 {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
}
</style>


import network from '../network'

export default class User {
    static async getUser(hash) {
        return await network.get('auth/user', {hash});
    }

    static async postSupportForm(data) {
        if (data) {
            return await network.post(`support`, data);
        }
        return null;
    }

}
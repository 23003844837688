<template>
	<div class="time">
		<img class="time__img" src="@/assets/icons/clock.svg" alt="icon">
		<p class="time__item"> {{ time }}</p>
	</div>
</template>
<script>
export default {
	name: 'Time',
	props: {},
	data() {
		return {
			interval: null,
			time: '',
		};
	},
	computed: {},
	created() {
		this.localTime()
	},
	methods: {
		localTime() {
			setInterval(() => {
				this.time = new Date().toLocaleTimeString('ru-Ru')
			}, 1000)
		},
	},
};
</script>

<style lang="scss">
.time {
	display: flex;
	align-items: center;
	justify-content: center;

	&__img {
		display: inline-flex;
		max-width: 16px;
		width: 100%;
		object-fit: cover;
		margin-right: 6px;
	}

	&__item {
		font-family: $font;
		font-size: 15px;
		font-weight: 500;
		color: $color-grey-20;
		margin: 0;
	}
}
</style>

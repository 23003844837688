<template>
	<div class="chat-window"
	     :class="{'chat-window_fullWidth':fullWidth}"
	>
		<div class="chat-window__header">
			<div class="chat-window__title">
				<Title
					styleType="card"
					title="Комментарии"/>
			</div>
			<div class="chat-window__controls">
				<Button
					styleType="icon"
					:iconBefore="require('@/assets/icons/window.svg')"
					@btnClick="toggleFullWidth"
				/>
				<Button
					styleType="close"
					:iconBefore="require('@/assets/icons/close.svg')"
					@btnClick="close"
				/>
			</div>
		</div>
		<div class="chat-window__content">
			<div class="chat-window__block"
			     v-if="chatData">
				<ChatMessageBlock
					v-for="block in chatData"
					:key="block.id"
					:role="role"
					:blockData="block"
				/>
			</div>

			<Preloader v-if=" preloader.chat > 0"/>
		</div>
		<div class="chat-window__footer"
		     v-if="modalData.isCanAddComment">
            <Textarea
	            :inputData="modalData"
	            @sendEvt="updateValue"
            />
			<Button
				styleType="circle"
				:iconBefore="require('@/assets/icons/arrow-white.svg')"
				@btnClick="postMessage"
			/>
		</div>
	</div>
</template>

<script>
import ChatMessageBlock from "@/components/chat/ChatMessageBlock"
import Button from "@/components/ui/buttons/Button"
import Title from "@/components/ui/titles/Title"
import Textarea from "@/components/ui/input/Textarea"
import Preloader from '@/components/ui/Preloader';

export default {
	components: {
		ChatMessageBlock,
		Button,
		Title,
		Textarea,
		Preloader
	},
	name: "ChatWindow",
	props: {
		modalData: [Array, Object]
	},
	data() {
		return {
			dataComment: null,
			fullWidth: false,
		};
	},
	computed: {
		userData() {
			return this.$store.getters.GET_USER;
		},
		role() {
			if (this.userData && this.userData.user && this.userData.user.role) {
				return this.userData.user.role
			}
			return null
		},
		preloader() {
			return this.$store.getters.PRELOADER;
		},
		chatData() {
			return this.$store.getters.GET_CHAT_DATA;
		},
	},
	mounted() {
		this.$store.dispatch('getComment', this.modalData)
	},
	methods: {
		postMessage() {
			if (this.dataComment) {
				let data = JSON.parse(JSON.stringify(this.modalData));
				data.comment = this.dataComment;
				this.modalData.comment = null;
				this.$store.dispatch('postComment', data);
			}
		},
		toggleFullWidth() {
			this.fullWidth = !this.fullWidth
		},
		close() {
			this.$emit('close')
		},
		updateValue(value) {
			this.dataComment = value;
		}
	},
};
</script>
<style lang="scss">
.chat-window {
	display: flex;
	flex-direction: column;
	min-width: 800px;
	width: calc(100vw - 920px);
	min-height: 508px;
	height: calc(100vh - 484px);
	border-radius: 10px;
	background-color: $color-white;
	transition: $transition-main;

	&_fullWidth {
		width: calc(100vw - 160px);
		height: calc(100vh - 160px);
	}

	&__header {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		height: 80px;
		border-bottom: 1px solid $color-light-grey;
		padding: 0 30px;
	}

	&__controls {
		display: flex;

		.button {
			margin-right: 20px;

			&__icon_before {
				width: 16px;
				height: 16px;
			}

			&:last-child {
				margin-right: 0;
			}
		}

	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		position: relative;
		overflow: auto;
		scrollbar-width: thin;
		scrollbar-color: $color-light-grey;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: $color-light-grey;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: $color-grey-20;
			border-radius: 10px;
		}

		.message-block {

			&:last-child {
				padding-bottom: 30px;
			}
		}

		.preloader {
			position: absolute;
		}
	}

	&__footer {
		display: flex;
		width: 100%;
		align-items: center;
		height: 80px;
		border-top: 1px solid $color-light-grey;

		.button {
			right: 30px;
			position: absolute;

			&__icon_before {
				width: 16px;
				height: 16px;
			}
		}

		.textarea {
			border-radius: 10px;

			&__input {
				height: 76px;
				border-radius: 10px;
				padding-right: 76px;
			}

		}
	}


}
</style>

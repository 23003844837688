import network from '../network';
import utils from '@/assets/js/utils';

export default class Form {
    static async getForm(formId, data) {
        let res = await network.get(`form/${formId}`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getFormDetail(route, data) {
        let res = await network.get(`form/${route.formId}/${route.year}/${route.quarter}`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async addForm(route) {
        let res = await network.post(`form/${route.formId}/${route.year}/${route.quarter}`, null, {});
        if (res.isSuccess) {
            return res
        }
    }

    static async addFormTenant(route, { code }) {
        let res = await network.post(`form/tenant/multiple/${route.year}`, {
            code
        },{});
        if (res.isSuccess) {
            return res
        }
    }

    static async delForm(route, data) {
        let res = await network.delete(`form/${route.formId}/${route.year}/${route.quarter}`, data, {});
        if (res.isSuccess) {
            return res
        }
    }

    static async delFormTenant(route, { id, code}) {
        let res = await network.delete(`form/tenant/multiple/${id}`, {
            code
        },{});
        if (res.isSuccess) {
            return res
        }
    }

    static async postForm(route, data) {
        let res = await network.patch(`form/${route.formId}/${route.year}/${route.quarter}`, data, {});
        if (res) {
            return res
        }
    }

    static async saveFile(data) {
        let res = await network.post(`form/tenant/files`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (res) {
            return res
        }
    }
}
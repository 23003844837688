import {createApp} from 'vue';
import Root from './App.vue';
import router from './router';
import store from './store';
import ApiPlugin from './plugins/api'
import Toast, {POSITION} from 'vue-toastification';
import network from '@/api/network';

const toastOptions = {
		position: POSITION.BOTTOM_RIGHT,
		shareAppContext: true,
		timeout: 2500,
		pauseOnHover: true,
}
network.configure();

const app = createApp(Root);
app.use(store);
app.use(router);
app.use(ApiPlugin);
app.use(Toast, toastOptions);
// app.mount('#app')
router.isReady().then(() => app.mount('#app'))






<template>
	<div class="scroll-top">
		<Button
			styleType="follow_up"
			:iconBefore="require('@/assets/icons/arrow-white.svg')"
			@click="scrollTop"
		/>
	</div>

</template>

<script>
import Button from '@/components/ui/buttons/Button';

export default {
	components: {Button},
	name: 'ScrollTop',
	methods: {
		scrollTop() {
			document.getElementById("app").scrollIntoView({behavior: "smooth"});
		}
	}
};
</script>
<style lang="scss">
.scroll-top {
	display: flex;
	justify-content: flex-end;
	align-self: center;
	position: fixed;
	bottom: 66px;
	max-width: 1344px;
	width: 100%;
	pointer-events: none;
}
</style>

import network from '../network';

export default class Review {

    static async getReview(formId, data) {
        let res = await network.get(`review/${formId}`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getReviewStatistics(route) {
        let res = await network.get(`review`, route.data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }
    static async getReviewStatisticsFilters() {
        let res = await network.get(`review/periods/filter`, {});
        if (res.isSuccess) {
            return res.result
        }
    }
    static async getReviewStatisticsFilter({data, route}) {
        let res = await network.get(`/review/filter/${data.year}/${data.quarter}`, route?.data, {});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getReviewDetail(route) {
        let res;
        if (route.quarter) res = await network.get(`review/${route.formId}/${route.year}/${route.quarter}`, route.data, {encode: false});
        else res = await network.get(`review/${route.formId}/${route.year}`, route.data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getReviewModal( data) {
        let res = await network.get(`report/filters`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getGisipIPModal( data) {
        let res = await network.get(`report/gisip_industrial_park`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getGisipTechnoparkModal( data) {
        let res = await network.get(`report/gisip_technopark`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getGisipModal( formId) {
        let res = await network.get(`report/${formId}`, null, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }
    static async getTenantModal( data) {
        let res = await network.get(`report/tenant`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }
    static async getLinkToReports(data) {
        let res = await network.get(`/download/${data.formId}/${data.year}`);
        if (res.isSuccess) {
            return res.result
        }
    }


    static async getDetailReviewModal(data) {
        let res = await network.get(`report/configureFilters`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getGeneralResidentReview(data) {
        return await network.post(`download/resident`, data, {encode: false});
    }

    static async postReviewModalData(data) {
        return await network.post(`report/generate`, data, {});
    }

    static async getGisipIPDownload(data) {
        return await network.get(`download/gisip_industrial_park/${data.year}`);
    }

    static async getGisipTechnoparkDownload(data) {
        return await network.get(`download/gisip_technopark/${data.year}`);
    }

    static async postReviewTransferData(data) {
        return await network.post(`report/copy`, data, {});
    }
}
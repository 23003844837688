import network from '../network';

export default class Mer {
    static async getMer(formId, page, data) {
        let res = await network.get(`mer/${formId}/${page}`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }
    
    static async getMerDetail(route, data) {
        let res = await network.get(`mer/${route.formId}/${route.year}/${route.quarter}`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getMerModal( data) {
        let res = await network.get(`/mer/filters`, data, {encode: false});
        if (res.isSuccess) {
            return res.result
        }
    }
    
    static async updMer(payload) {
        let res = await network.patch(`mer/${payload.formId}/${payload.year}/${payload.quarter}`, payload.data, {encode: false});
        if (res.isSuccess) {
            return res
        }
    }

    static async acceptMer(payload) {
        let res = await network.post(`mer/${payload.formId}/${payload.year}/${payload.quarter}/accept`, payload.data, {encode: false});
        if (res.isSuccess) {
            return res
        }
    }

    static async addReportMer(payload) {
        let res = await network.post(`mer/${payload.formId}`, {encode: false});
        if (res.isSuccess) {
            return res
        }
    }

    static async postMerModalData(data) {
        return await network.post(`mer/generate`, data, {});
    }

    static async postBusyEditTable(payload) {
        return await network.post(`/mer/${payload.formId}/${payload.year}/${payload.quarter}`, {});
    }
}
<template>
	<div class="review-modal"
	     :class="{'review-modal_fullWidth':fullWidth}"
	>
		<div class="review-modal__header">
			<div class="review-modal__title"
			     v-if="title"
			>
				<Title
					styleType="section"
					:title="title"/>
			</div>
			<div class="review-modal__controls">
				<Button
					styleType="icon"
					:iconBefore="require('@/assets/icons/window.svg')"
					@btnClick="toggleFullWidth"
				/>
				<Button
					styleType="close"
					:iconBefore="require('@/assets/icons/close.svg')"
					@btnClick="close"
				/>
			</div>
		</div>
		<div class="review-modal__content">
			<div class="review-modal__block"
			     v-if="reviewModalData"
			>
				<div class="review-modal__block-item"
				     v-for="(componentData, index) in reviewModalData"
				     :key="index"
				>
					<div class="review-modal__block-title"
					     v-if="componentData.title"
					>
						{{ componentData.title }}
						{{ componentData.required ? '*' : '' }}
					</div>
					<div
						:class="selectsClasses"
					>
						<component
							v-if="component"
							:is="component(componentData)"
							:entityId="componentData.code"
							:dataSelect="componentData.options"
							:isRequired="componentData.required"
							@sendEvt="setPostData"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="review-modal__footer-description">
			* - поле обязательное для заполнения
		</div>
		<div class="review-modal__footer">
			<Button
				title="Скачать отчет"
				:disabled="disabledByRequiresFields"
				@btnClick="downloadReport"
			/>
			<Button
				v-if="isModalCodeReview && !hideFormReportButton"
				styleType="white"
				title="Сформировать отчет"
				@btnClick="showModal('reviewDetail')"
			/>
			<Button
				v-if="!isModalCodeReview"
				styleType="white"
				title="Отменить"
				@btnClick="showModal"
			/>
		</div>
		<Preloader v-if="preloader.review > 0"/>
	</div>
</template>

<script>
import Button from "@/components/ui/buttons/Button"
import Title from "@/components/ui/titles/Title"
import Preloader from '@/components/ui/Preloader';
import MultiSelect from '@/components/ui/select/MultiSelect';
import Select from '@/components/ui/select/Select';

export default {
	components: {
		Button,
		Title,
		Preloader
	},
	name: "ReviewModal",
	props: {
		modalData: [Array, Object]
	},
	data() {
		return {
			fullWidth: false,
			postData: [],
			requiredFields: [],
			chooseFields: [],
			disabledByRequiresFields: true,
      hideFormReportButton: false
		};
	},
	computed: {
		title() {
			let title = null;
			if (this.isModalCodeReview) {
				title = 'Консолидированный отчет'
			}
			if (this.modalData?.code === 'reviewDetail') {
				title = 'Сформировать отчет'
			}
			return title
		},
		userData() {
			return this.$store.getters.GET_USER;
		},
		role() {
			if (this.userData?.user && this.userData.user.role) {
				return this.userData.user.role
			}
			return null
		},
		preloader() {
			return this.$store.getters.PRELOADER;
		},
		reviewModalData() {
			if (this.isModalCodeReview) {
				this.requiredFields = this.$store.getters.GET_REVIEW_MODAL_DATA?.map((el) => {
					if (el.required) {
						return el
					}
				})
				return this.$store.getters.GET_REVIEW_MODAL_DATA;
			} else {
				let reqFields = this.$store.getters.GET_REVIEW_DETAIL_MODAL_DATA;
				this.requiredFields = reqFields?.filter(el => el.required)

				return this.$store.getters.GET_REVIEW_DETAIL_MODAL_DATA;
			}
		},
		isModalCodeReview() {
			return this.modalData?.code === 'review'
		},
		selectsClasses() {
			return [
				'review-modal__block-selects',
				!this.isModalCodeReview && 'review-modal__block-selects--reports',
				this.fullWidth && 'review-modal__block-selects--fullWidth',
			]
		}
	},
	mounted() {
    const currentUrl = window.location.href;

    if (this.isModalCodeReview) {
      if (currentUrl.includes('gisip_industrial_park')) {
        this.$store.dispatch('getGisipIPModal', this.modalData);
        this.hideFormReportButton = true;
      } else if (currentUrl.includes('gisip_technopark')) {
        this.$store.dispatch('getGisipTechnoparkModal', this.modalData);
        this.hideFormReportButton = true;
      }else {
        this.$store.dispatch('getReviewModal', this.modalData)
      }
    }

    if (this.modalData?.code === 'reviewDetail') {
      this.$store.dispatch('getDetailReviewModal', this.modalData)
    }
	},
	methods: {
		setPostData(item) {
			if (item.required) {
				this.chooseFields.push(item.value)
			}

			if (this.chooseFields.length === this.requiredFields.length) {
				this.disabledByRequiresFields = false
			}
			this.postData[item.id] = item.value;
		},
		component(component) {
			if (component.isMultiple) {
				return MultiSelect
			}
			if (!component.isMultiple) {
				return Select
			}
		},
		toggleFullWidth() {
			this.fullWidth = !this.fullWidth
		},
		downloadReport() {
      const currentUrl = window.location.href;
      if (currentUrl.includes('gisip_industrial_park')) {
        this.$store.dispatch('getGisipIPDownload', {...this.postData});
      } else if(currentUrl.includes('gisip_technopark')){
        this.$store.dispatch('getGisipTechnoparkDownload', {...this.postData});
      }else {
        this.$store.dispatch('postReviewModal', {...this.postData});
      }
		},
		close() {
			this.$emit('close')
		},
		showModal(code = 'review') {
			this.postData = [];
      const currentUrl = window.location.href;
			if (code === 'review') {
        if (currentUrl.includes('gisip_industrial_park')) {
          this.$store.dispatch('getGisipIPModal', this.modalData);
        } else if (currentUrl.includes('gisip_technopark')) {
          this.$store.dispatch('getGisipTechnoparkModal', this.modalData);
        } else{
          this.$store.dispatch('getReviewModal', this.modalData);
        }
			}
			if (code === 'reviewDetail') {
				this.$store.dispatch('getDetailReviewModal', this.modalData)
			}

			let data = {
				code: code
			}
			this.$store.commit('SET_MODAL_DATA', data)
		},
	},
};
</script>

<style lang="scss">
.review-modal {
	display: flex;
	flex-direction: column;
	max-width: 700px;
	min-height: 340px;
	border-radius: 10px;
	background-color: $color-white;
	transition: $transition-main;
	padding: 40px;

	&_fullWidth {
		width: calc(100vw - 160px);
		height: calc(100vh - 160px);
	}

	&__header {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 58px;
	}

	&__controls {
		display: flex;
		margin-left: 22px;

		.button {
			margin-right: 20px;

			&__icon_before {
				width: 16px;
				height: 16px;
			}

			&:last-child {
				margin-right: 0;
			}
		}

	}

	&__block-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 600px;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		.multi-select {
			max-width: 500px;
			height: 48px;

			&__options {
				top: 48px;
			}
		}

		.select {
			max-width: 500px;
		}
	}

	&__block-title {
		margin-right: 10px;
		font-family: $font;
		@include fontStyle(16px, 500);
	}

	&__block-selects {
		width: 100%;
		max-width: 400px;
	}

	&__block-selects--reports {
		max-width: 300px;
	}

	&__block-selects--fullWidth {
		max-width: 500px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		position: relative;
		// overflow: auto;
		scrollbar-width: thin;
		scrollbar-color: $color-light-grey;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: $color-light-grey;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: $color-grey-20;
			border-radius: 10px;
		}

		.message-block {

			&:last-child {
				padding-bottom: 30px;
			}
		}

		.preloader {
			position: absolute;
		}
	}

	&__footer {
		display: flex;
		width: 100%;
		align-items: center;
		height: 80px;
		margin-top: 20px;

		.button {
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__footer-description {
		margin-top: 18px;
	}
}
</style>

<template>
	<div class="filter-modal">
		<div class="filter-modal__controls">
			<Button
				styleType="close"
				:iconBefore="require('@/assets/icons/close.svg')"
				@btnClick="close"
			/>
		</div>
		<div class="filter-modal__items">
			<div
				class="filter-modal__item"
				v-for="item in modalData.filters"
			>
				<div class="filter-modal__item-title">{{ item.label }}</div>
				<div class="filter-modal__options"
				>
					<div
						class="filter-modal__option"
						v-for="option in item.options"
					>
						<label :for="option.title"
						>
							<input
								class="filter-modal__checkbox"
								type="checkbox"
								:name="option.title"
								:id="option.title"
								:value="{...option, type: item.name}"
								v-model="checkedItems"
								@change="onCheckValidForm"
							/>
							<span class="filter-modal__checkbox-point"></span>
							<span class="filter-modal__checkbox-text">{{ option.title }}</span>
						</label>
					</div>
				</div>
			</div>
		</div>

		<div class="filter-modal__block-error">
			<div v-if="errorValidForm" class="filter-modal__error">
				{{errorValidForm}}
			</div>
		</div>

		<div class="filter-modal__btn">
			<Button
				styleType="header"
				title="Поиск по резидентам"
				@click="setFilterParams"
			></Button>
		</div>
	</div>
</template>

<script>
import Button from "@/components/ui/buttons/Button.vue";
import Checkbox from "@/components/ui/checkbox/Checkbox.vue";

export default {
	name: "FilterModal",
	props: {
		modalData: [Object],
	},
	data() {
		return {
			checkedItems: this.modalData.addedFilters ? [...this.modalData.addedFilters] : [],
			errorValidForm: '',
		}
	},
	components: {
		Button,
		Checkbox
	},
	methods: {
		close() {
			this.$emit('close')
		},
		setFilterParams() {
			if (this.checkedItems.length === 0) {
				this.errorValidForm = "Для поиска необходимо выбрать хотя бы одно значение"
				return
			}

			this.$store.commit('SET_FILTER_PARAMS', this.checkedItems);
			this.$emit('searchResidents');
			this.close();
		},
		onCheckValidForm() {
			if (this.checkedItems.length > 0) {
				this.errorValidForm = ""
			}
		}
	}
}
</script>

<style lang="scss">
.filter-modal {
	display: flex;
	flex-direction: column;
	max-width: 420px;
	height: 100%;
	border-radius: 10px;
	background-color: $color-white;
	transition: $transition-main;
	padding: 0 20px 40px 20px;
	position: relative;

	&__block-error {
		height: 25px;
		padding: 0 10px;
	}

	&__error {
		color: $color-danger;
	}

	&__controls {
		position: absolute;
		top: 20px;
		right: 20px;
	}

	&__item {
		border-bottom: 1px solid $color-light-grey;
		padding: 20px 0 15px;

		&:last-child {
			border-bottom: none;
		}
	}

	&__item-title {
		font-family: $font, sans-serif;
		@include fontStyle(14px, 500);
		color: $color-dark-grey;
		margin-bottom: 25px;
	}

	&__option {
		font-family: $font, sans-serif;
		@include fontStyle(15px, 400);
		color: $color-dark-grey;
		margin-bottom: 20px;
		padding-left: 30px;
		position: relative;

		&:last-child {
			margin-bottom: 0;
		}
	}

	label {
		display: block;
		cursor: pointer;
	}

	&__checkbox {
		position: absolute;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);
	}

	&__checkbox-point {
		width: 18px;
		height: 18px;
		border-radius: 2px;
		position: absolute;
		left: 0;
		top: 0;
		border: 1px solid $color-grey-20;
		transition: $transition-main;
		background: url("@/assets/icons/mark_white.svg") center / 80% no-repeat, $color-white;
	}

	&__checkbox-text {
		font-family: $font, sans-serif;
		@include fontStyle(15px, 400);
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 19px;
		color: $color-dark-grey;
	}

	&__btn {
		align-self: center;
	}

	label input:checked ~ .filter-modal__checkbox-text {
		color: $color-danger;
	}

	label input:checked ~ .filter-modal__checkbox-point {
		background: url("@/assets/icons/mark_white.svg") center / 80% no-repeat, $color-danger;
		border: 1px solid $color-danger;
	}
}
</style>
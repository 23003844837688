import network from '../network';
import utils from '@/assets/js/utils';

export default class Reports {

    static async getReports(page) {
        let res = await network.get(`/resident/${page}`, {});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async addReport() {
        let res = await network.post(`form/`, null, {});
        if (res.isSuccess) {
            return res.result
        }
    }

}
<template>
	<div class="chat-message-time"
	     :class="{'chat-message-time_incoming': isIncoming}">{{ messageTime }}
	</div>
</template>

<script>
export default {
	name: "ChatMessageTime",
	props: {
		isIncoming: Boolean,
		messageTime: String,
	},
}
</script>

<style lang="scss">
.chat-message-time {
	font-family: $font;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: $color-grey-20;
	display: flex;
	justify-content: flex-end;

	&_incoming {
		flex-direction: row-reverse;
	}
}
</style>